@tailwind base;

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: local('Rubik'), local('Rubik-Regular'),
    url('./fonts/rubik-v9-cyrillic_latin-regular.woff2') format('woff2'),
    url('./fonts/rubik-v9-cyrillic_latin-regular.woff') format('woff');
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: local('Rubik Medium'), local('Rubik-Medium'),
    url('./fonts/rubik-v9-cyrillic_latin-500.woff2') format('woff2'),
    url('./fonts/rubik-v9-cyrillic_latin-500.woff') format('woff');
}

@tailwind components;
@tailwind utilities;


button:active, button:focus {
    outline: none;
}

a {
    /* чтобы не было задержки в 300 мс при таче на ссылки на мобилках в Safari
    возможно скоро уберут вслед за хром и фаерфокс
    https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away*/
    touch-action: manipulation;
}

/* Наши утилиты */
@variants responsive {
    /* Значения ширины главного контейнера */
    .container-w-md {
        width: 960px;
    }

    .container-w-lg {
        width: 1140px;
    }

    .container-w-xl {
        width: 1440px;
    }

    /* Значения ширины главного контейнера */
    /* Значения ширины главного контейнера лендингов affiliate */
    .container-affiliate-w-md {
        width: 928px;
    }

    .container-affiliate-w-lg {
        width: 1108px;
    }

    .container-affiliate-w-xl {
        width: 1168px;
    }

    /* Значения ширины главного контейнера лендингов affiliate */
    .-hack-right-108px {
        right: -108px;
    }

    .-hack-top-88 {
        top: -88px;
    }
}

button:focus {
    outline: none;
}

/* width */
.hack-w-44 {
    width: 44%;
}

.hack-w-70px {
    width: 70px;
}

.hack-w-155px {
    width: 155px;
}

.hack-w-160px {
    width: 160px;
}

.hack-w-250px {
    width: 250px;
}

/* width */

/* height */
.hack-h-91px {
    height: 91px;
}

.hack-h-95px {
    height: 95px;
}

.hack-h-112px {
    height: 112px;
}

.hack-h-300px {
    height: 300px;
}

.hack-h-208px {
    height: 208px;
}

.hack-h-400px {
    height: 400px;
}

/* height */

/* right */
.hack-bottom-13 {
    right: 13%;
}

.hack-right-16px {
    right: 16px;
}

.hack-right-24px {
    right: 24px;
}

.hack-right-32px {
    right: 32px;
}

.hack-right-40px {
    right: 40px;
}

.-hack-right-94px {
    right: -94px;
}

.hack-right-25 {
    right: 25%;
}

/* right */

/* left */
.hack-left-25 {
    left: 25%;
}

.-hack-left-43px {
    left: -43px;
}

.-hack-left-50px {
    left: -50px;
}

.hack-left-50 {
    left: 50%;
}

/* left */

/* top */
.hack-top-30px {
    top: 30px;
}

.-hack-top-40px {
    top: -40px;
}

.-hack-top-46 {
    top: -46px;
}

/* top */

/* bottom */
.hack-bottom-30px {
    bottom: 30px;
}

.hack-bottom-60px {
    bottom: 60px;
}

/* bottom */

/* transform */
.-hack-skew-x-38px {
    --transform-skew-x: -38deg
}

.hack-rotate-38px {
    --transform-rotate: 38deg
}

.hack-rotate-38px {
    --transform-rotate: 35deg
}

/* transform */

/* max-width */
.hack-max-w-115px {
    max-width: 115px;
}

.hack-max-w-240px {
    max-width: 240px;
}

.hack-max-w-272px {
    max-width: 272px;
}

.hack-max-w-1050px {
    max-width: 1050px;
}

.hack-max-w-fit-content {
    max-width: fit-content;
}

/* max-width */

/* min-width */
.hack-min-w-104px {
    min-width: 104px;
}

.hack-min-w-128px {
    min-width: 128px;
}

.hack-min-w-280px {
    min-width: 280px;
}

.hack-min-w-152px {
    min-width: 152px;
}

.hack-min-w-160px {
    min-width: 160px;
}

/* min-width */

/* offer banner */
.hack-offer-banner::after {
    content: '';
    border: 45px solid transparent;
    border-left-width: 45px;
    border-top-width: 45px;
    border-left-color: inherit;
    border-top-color: inherit;
    right: -90px;
    position: absolute;
    top: 0;
}

.hack-offer-banner::before {
    content: '';
    border: 45px solid transparent;
    border-right-width: 45px;
    border-bottom-width: 45px;
    border-right-color: inherit;
    border-bottom-color: inherit;
    left: -90px;
    position: absolute;
    top: 0;
}

/* offer banner */

/* Markdown */
.hack-markdown-syles {
    @apply text-blueGray-500 overflow-hidden break-words list-decimal pl-1;
}

.hack-markdown-syles ol li {
    @apply list-decimal list-inside text-blueGray-600 text-[16px] pl-1.5;
}

.hack-markdown-syles h4 {
    @apply my-5 mx-0;
}

.hack-markdown-syles ul {
    @apply my-4 mx-0 pl-10;
}

.hack-markdown-syles li {
    @apply list-disc;
}

.hack-markdown-syles p {
    @apply my-4 mx-0;
}

.hack-markdown-syles strong {
    @apply font-medium;
}

.hack-markdown-syles a {
    @apply underline text-blueGray-600;
}

.hack-markdown-syles table {
    @apply bg-white border-collapse w-full table-fixed;
}

.hack-markdown-syles thead {
    @apply border border-blueGray-200;
}

.hack-markdown-syles th {
    @apply bg-blueGray-100 text-blueGray-500 uppercase text-left font-medium py-4 px-6 text-p2;
}

.hack-markdown-syles tr {
    @apply border border-blueGray-300;
}

.hack-markdown-syles td {
    @apply py-4 px-6 font-normal text-blueGray-600 text-p2;
}

/* Markdown */

/* scrollbar */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE, EDGE */
    scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari, Opera */
    display: none;
}

/* scrollbar */

.smooth {
    scroll-behavior: smooth;
}

.markdown h2, .markdown h1 {
    @apply mb-6 mt-6 font-medium text-blueGray-600 text-p1;
}

.markdown h3 {
    @apply inline-block font-medium text-blueGray-600;
}

.markdown h3 + h3 {
    @apply mt-8;
}

.markdown p {
    @apply mb-8 text-blueGray-600 text-[16px];
}

.markdown > p strong {
    @apply font-medium text-red-500 not-italic;
}

.markdown ol {
    @apply list-decimal list-inside text-blueGray-600 text-[16px];
    counter-reset: item;
}

.markdown ul {
    @apply list-disc list-inside text-blueGray-600 text-[16px];
}

.markdown a {
    @apply underline;
}

.markdown a:hover {
    @apply transition hover:text-red-600;
}

.markdown li p {
    @apply inline mb-0;
}

.markdown li:first-child h3 {
    @apply mt-0;
}

.markdown li h3 {
    @apply mt-8 mb-6;
}

.markdown li p + p {
    @apply block;
}

/* .markdown ol > li {
    @apply mt-8;
} */

.markdown ol ul {
    @apply ml-3;
}

.markdown .markdown-link {
    @apply font-medium text-red-500 no-underline;
}

.markdown ol ol {
    @apply ml-4;
}

.markdown ol > li {
    display: block
}

.markdown ol > li:before {
    @apply inline-flex text-blueGray-600 text-[16px];

    content: counters(item, ".") ".\00a0";
    counter-increment: item;
}


/** Support markdown **/
.support-markdown p {
    @apply text-blueGray-600;
    margin: 0;
    padding: 0;

}

.support-markdown > p strong {
    @apply text-blueGray-600;
    font-weight: bold;
}

.support-markdown ul {
    margin: 0;
    padding: 0;
}

.support-markdown blockquote {
    @apply border-l-4 border-solid border-blueGray-100 text-[16px];
    padding: 10px 20px;
    margin: 0 0 20px;
}

.support-markdown h1 {
    @apply font-medium text-h1 mt-0 mb-2;
}

.support-markdown h2 {
    @apply font-medium text-h2 mt-0 mb-2;
}

.support-markdown h3 {
    @apply font-medium text-sub mt-0 mb-2;
}

.support-markdown h4 {
    @apply font-medium mt-0 mb-2;
}

.support-markdown h5 {
    @apply font-medium mt-0 mb-2;
}

.support-markdown h6 {
    @apply font-medium mt-0 mb-2;
}

/** /Support markdown **/
