.footer{
    background-color: #fff;
    border-top: 1px solid #F1F5F9;
    .wrapper{
        padding: 24px 0;
        .description{
            flex-direction: column;
            align-items: flex-start;
            .changeLang{
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 16px;
                margin-left: -4px;
                a{
                    color: #001540;
                }
            }
        }
        .descriptionMobile{
            margin-top: 24px;
            text-align: center;
        }
        .descriptionDesktop{
            display: none;
        }
    }
    ul{
        li{
            color: #4B5A79;
            margin: 16px 0;
            font-size: 14px;
            a{
                color: #001540;
            }
        }
    }
    p{
        color: #C0C5D0;
        font-size: 12px;
    }
    .payments {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 19px;
        margin-top: 24px;
        img{
            height: 22px;
            &:nth-last-child(-n+2){
                grid-column: span 2;
                margin: auto;
                width: 79px;
            }
        }
    }
    .copyright{
        display: none;
    }
}

.container{
    margin-left: auto;
    margin-right: auto;
    width: 304px;
}

@media screen and (min-width: 576px) {
    .footer{
        .wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 40px 0;
            gap: 24px;
            .description{
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
            .descriptionMobile{
                display: flex;
                justify-content: center;
                margin-top: 24px;
                grid-column: 1 / -1;
                p{
                    width: 322px;
                }
            }
        }
        .payments{
            grid-column: span 2;
            display: flex;
            justify-content: space-between;
            margin-top: 0;
            img{
                &:nth-last-child(-n+2){
                    margin: 0;
                    width: auto;
                }
            }
        }
    }
    .container{
        width: 560px;
    }
}

@media screen and (min-width: 768px) {
    .footer{
        .wrapper{
            gap: 40px;
        }
        .payments{
            font-size: 14px;
        }
    }
    .container{
        width: 678px;
    }
}

@media screen and (min-width: 992px) {
    .footer{
        .wrapper{
            .descriptionMobile{
                display: none;
            }
            .descriptionDesktop{
                display: block;
                text-align: right;
            }
        }
        .payments{
            grid-column: span 1;
        }
        .copyright{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            p{
                color: #64748B;
            }
        }
    }
    .container{
        width: 928px;
    }
}

@media screen and (min-width: 1200px) {
        .container{
            width: 1108px;
        }
}

@media screen and (min-width: 1600px) {
        .container{
            width: 1264px;
        }
}
