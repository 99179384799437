//need to use !important for rewriting styles from Button component from epn-ui
.sharedStyles {
    min-width: fit-content !important;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    height: 40px;
    background: rgba(241, 245, 249, 1);
    color: #64748b;

    &:hover {
        color: #1e293b;
        background-color: #f1f5f9;
    }
    margin-right: 16px;
    margin-bottom: 16px;
}
