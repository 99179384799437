.sharedStyles {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    height: 40px;
    margin-right: -8px;
    white-space: nowrap;
    font-size: 16px;

    &:hover {
        color: #1e293b;
    }
}
