.toast {
    top: 0;
    right: 0;
    padding: 24px 8px;
    width: 100%;

    .Toastify__toast-body {
        margin-right: 8px;
    }

    .Toastify__toast {
        display: flex;
        align-items: center;
        color: white;
        border-radius: 1px;
        min-height: 53px;

        &--error {
            background-color: #f87171;
            box-shadow: 0 2px 10px rgba(255, 102, 102, 0.25);
        }

        &--info {
            color: #fff;
            background-color: #1e293bed;
            border-radius: 3px;
            opacity: 0.9;
            padding: 16px 24px;
            font-size: 16px;

            .Toastify__toast-body {
                margin-right: 0;
            }

            svg {
                display: none;
            }
        }

        &--warning {
            background-color: #fbbf24;
            box-shadow: 0 2px 10px rgba(255, 215, 74, 0.25);
        }

        &--success {
            background-color: #22c55e;
            box-shadow: 0 2px 10px rgba(50, 208, 118, 0.25);
        }
    }
}
