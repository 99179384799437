.errorPage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__content {
        text-align: center;
        @media (min-width: 320px) {
            position: relative;
            padding: 0 0 50px 0;
        }
        @media (min-width: 576px) {
            padding: 100px 0 200px 0;
        }
        @media (min-width: 1200px) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
        }
    }
    &__codeBg {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 600px;
        line-height: 711px;
        color: #ffffff;
        text-shadow: -1px 0 #E2E8F0, 0 1px #E2E8F0, 1px 0 #E2E8F0, 0 -1px #E2E8F0;
        @media (min-width: 320px) {
            display: none;
        }
        @media (min-width: 1200px) {
            display: block;
        }
    }
    &__code {
        font-weight: 700;
        color: #f87171;
        @media (min-width: 320px) {
            font-size: 100px;
            line-height: 137px;
        }
        @media (min-width: 576px) {
            font-size: 200px;
            line-height: 237px;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        color: #1e293b;
    }
    &__action {
        margin-top: 40px;
    }
}